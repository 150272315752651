import { camelCase } from 'lodash'
import store from '@/store'

export const filterTableMixin = {
  methods: {
    filterTable() {
      const rows = this.$refs.table.querySelectorAll('tbody tr')
      const search = this.$refs.filter.value
        .replace(/ +/g, ' ')
        .toLowerCase()
      Array.prototype.filter.call(rows, row => {
        if (!row.dataset.val) return false
        const text = row.dataset.val.trim().toLowerCase()
        if (text.indexOf(search) === -1) {
          row.style.display = 'none'
          return true
        }
        if (window.innerWidth > 600) {
          row.style.display = 'table-row'
        } else {
          row.style.display = 'block'
        }
        return false
      })
      this.$emit('filtered', search)
    }
  }
}

export const exportAsCSVMixin = {
  methods: {
    compareColumnNames(a, b, lastCols) {
      if (lastCols && lastCols.includes(a[0])) {
        return 1
      }
      if (lastCols && lastCols.includes(b[0])) {
        return -1
      }
      if (a[0] > b[0]) return 1
      if (a[0] < b[0]) return -1
      return 0
    },
    csvExport(arrData, fileName, lastCols) {
      // make sure column names are in the same order for each element expect last cols
      const sortedArrData = arrData.map(row =>
        Object.fromEntries(
          Object.entries(row).sort((a, b) =>
            this.compareColumnNames(a, b, lastCols)
          )
        )
      )

      let csvContent = 'data:text/csv;charset=utf-8,'
      csvContent += [
        Object.keys(sortedArrData[0])
          .map(key => camelCase(key))
          .join(';'),
        ...sortedArrData.map(item => Object.values(item).join(';'))
      ]
        .join('\n')
        .replace(/(^\[)|(\]$)/gm, '')

      const data = encodeURI(csvContent)
      const link = document.createElement('a')
      link.setAttribute('href', data)
      link.setAttribute(
        'download',
        `${store.state.common.$organisation}-${fileName}.csv`
      )
      link.click()
    }
  }
}

export default { filterTableMixin, exportAsCSVMixin }
