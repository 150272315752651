<template>
  <model-list-select
    v-model="selectedUsername"
    :list="optionsList"
    :title="$t('typeToSearch')"
    option-value="username"
    :custom-text="
      item => {
        return item.firstName + ' ' + item.lastName
      }
    "
    :filter-predicate="
      (text, inputText) => {
        return true
      }
    "
    :is-error="search.length > 0 && search.length < 3"
    :placeholder="$t('typeToSearch')"
    :style="{ width: width }"
    class="custom-select"
    @searchchange="onSearchChange"
  />
</template>

<script>
import _find from 'lodash/find'
import { ModelListSelect } from 'vue-search-select'

import OkkuApi from '@/services/OkkuApi'

import 'vue-search-select/dist/VueSearchSelect.css'

export default {
  name: 'UserSelector',
  components: {
    ModelListSelect
  },
  props: {
    currUser: {
      type: String,
      default: ''
    },
    currUserObject: {
      type: Object,
      default() {
        return {}
      }
    },
    width: {
      type: String,
      default: '100%'
    }
  },
  data() {
    return {
      selectedUsername: this.currUser,
      selectedUser: null,
      options: [],
      timeoutHandler: null,
      search: ''
    }
  },
  computed: {
    optionsList() {
      let optionsList = []
      if (this.options.length) {
        optionsList = this.options
      } else if (
        this.currUser &&
        this.currUserObject.context?.lastName
      ) {
        optionsList.push({
          username: this.currUserObject.regularUser,
          firstName: this.currUserObject.context.firstName,
          lastName: this.currUserObject.context.lastName
        })
      } else if (this.currUser && typeof this.currUser !== 'string') {
        optionsList.push({
          username: this.currUser.email,
          firstName: this.currUser.firstName,
          lastName: this.currUser.lastName
        })
      }
      return optionsList
    }
  },
  watch: {
    selectedUsername(val) {
      if (val) {
        const user = _find(this.optionsList, { username: val })
        if (user) {
          this.selectedUser = user
          this.$emit('updateUser', this.selectedUser)
        }
      }
    },
    users() {
      this.options = this.users
    },
    currUser(val) {
      if (val && typeof val !== 'string') {
        this.selectedUsername = val.email
      } else {
        this.selectedUsername = val
      }
      this.options = []
    }
  },
  methods: {
    onSearchChange(search) {
      this.search = search || ''
      clearTimeout(this.timeoutHandler)
      if (!search) {
        if (
          !_find(this.options, {
            username: this.selectedUsername
          }) &&
          this.selectedUser
        ) {
          this.options.unshift(this.selectedUser)
        }
        return
      }
      if (search.length > 2) {
        this.timeoutHandler = setTimeout(() => {
          OkkuApi.getBuildingUsers(search).then(response => {
            this.options = response.users
          })
        }, 1000)
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.ui.dropdown::v-deep {
  color: #495057 !important;
  border: 1px solid #ced4da !important;
  display: inline-block !important;
  height: 38px;
  font-size: 16px;
  white-space: nowrap !important;
  padding: 11px 10px !important;
  border-radius: 4px !important;
  .dropdown.icon {
    margin-right: -11px !important;
    padding: 10px 7px !important;
    background-color: #fff;
    z-index: 4 !important;
    position: relative !important;
    top: 0 !important;
    opacity: 1 !important;
    right: 2px !important;
  }
  .menu {
    border-color: #ced4da !important;
  }
  .item.item.item.item {
    padding: 10px !important;
  }
}
</style>
