<template>
  <b-modal id="modal-availability" size="lg" :title="`${this.$t('availability')} ${selectedObject.roomName
    ? selectedObject.roomName + ', ' + selectedObject.name
    : selectedObject.name
    }`
    " hide-footer>
    <div v-if="timeSegments.length" class="wrapper ml-2">
      <div v-for="(value, index) in timeSegments" :key="index" class="flex-grow">
        <div class="hr-wrapper bg-light py-1">
          <span :class="index > 9 ? 'hr-label-2' : 'hr-label'">
            {{ index }}
          </span>
        </div>
        <div class="wrapper mt-2">
          <div v-b-tooltip.hover :title="value['00'].title" :class="['tile', `bg-${value['00'].status}`]" />
          <div v-b-tooltip.hover :title="value['15'].title" :class="['tile', `bg-${value['15'].status}`]" />
          <div v-b-tooltip.hover :title="value['30'].title" :class="['tile', `bg-${value['30'].status}`]" />
          <div v-b-tooltip.hover :title="value['45'].title" :class="['tile', `bg-${value['45'].status}`]" />
        </div>
      </div>
      <div class="hr-wrapper py-1">
        <span class="hr-label-2">24</span>
      </div>
    </div>
  </b-modal>
</template>
<script>
import { mapState } from 'vuex'
import moment from 'moment-timezone'
import OkkuApi from '@/services/OkkuApi'

export default {
  props: {
    isKiosk: {
      type: Boolean,
      default: false
    },
    startTime: {
      type: String,
      required: true
    },
    endTime: {
      type: String,
      required: true
    },
    buildingTimeslots: {
      type: Array,
      required: true
    },
    buildingTimezone: {
      type: String,
      required: true
    },
    selectedObject: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    currentObjectTimeslots: [],
    timeSegments: [],
    timeFormat: 'HH:mm'
  }),
  computed: {
    ...mapState('common', ['desks', 'rooms'])
  },
  watch: {
    async selectedObject(selectedObject) {
      if (!this.selectedObject?.id) {
        return
      }
      const reservations = await this.getTodaysReservationsForObject()

      // TODO: add user abcenses - get user reservations as on fing my colleague screen
      const openingTime = this.openingTime()
      const closingTime = this.closingTime()

      reservations.forEach(r => {
        // compensate for fixed desks
        if (moment(r.start_datetime).isSameOrBefore(openingTime)) {
          r.start_datetime = openingTime.toISOString()
        }
        if (moment(r.end_datetime).isSameOrAfter(closingTime)) {
          r.end_datetime = closingTime.toISOString()
        }
        return r
      })

      this.filterTimeslotsByRoom()
      this.calculateTimeSegments(reservations)
    }
  },

  methods: {
    async getTodaysReservationsForObject() {
      if (this.isKiosk) {
        return (
          await OkkuApi.getTodaysReservationsForObjectForKiosk(
            this.selectedObject.id,
            this.startTime
          )
        ).data
      }
      return (
        await OkkuApi.getTodaysReservationsForObject(
          this.selectedObject.id,
          this.startTime
        )
      ).data


    },
    formatTime(time) {
      return moment(time)
        .tz(this.buildingTimezone)
        .format(this.timeFormat)
    },

    getRoomId() {
      if (this.selectedObject.type === 'DESK') {
        return this.selectedObject.roomId
      }
      return this.selectedObject.id
    },

    filterTimeslotsByRoom() {
      const roomId = this.getRoomId()

      this.currentObjectTimeslots = this.buildingTimeslots.filter(
        timeslot => timeslot.objectId === roomId
      )
    },

    calculateTimeSegments(reservations) {
      if (!reservations) return

      const segments = []
      const openingTime = this.getTimeObjects(this.openingTime())
      const closingTime = this.getTimeObjects(this.closingTime())

      const timeslots = this.currentObjectTimeslots.length
        ? this.currentObjectTimeslots
        : this.buildingTimeslots

      for (let index = 0; index < 24; index += 1) {
        const hour = index > 9 ? index : `0${index}`
        const startOfHour = moment(`${hour}:00`, this.timeFormat)
        const endOfHour = moment(`${hour}:59`, this.timeFormat)

        if (
          startOfHour.isBetween(
            openingTime,
            closingTime,
            undefined,
            '[]'
          ) ||
          endOfHour.isBetween(
            openingTime,
            closingTime,
            undefined,
            '[]'
          )
        ) {
          const segmentsData = ['00', '15', '30', '45'].map(
            minute => {
              const startOfSegment = moment(
                `${hour}:${minute}`,
                this.timeFormat
              )
              const endOfSegment = moment(
                `${hour}:${Number(minute) + 14}`,
                this.timeFormat
              )
              const isBuildingOpen = this.buildSegment(
                startOfSegment,
                endOfSegment,
                timeslots
              )
              return isBuildingOpen
                ? this.getVisualTimeSegmentObject(
                  reservations,
                  startOfSegment,
                  endOfSegment
                )
                : this.getVisualTimeSegmentParameters('Closed')
            }
          )

          segments.push({
            '00': segmentsData[0],
            '15': segmentsData[1],
            '30': segmentsData[2],
            '45': segmentsData[3]
          })
        } else {
          segments.push({
            '00': this.getVisualTimeSegmentParameters('Closed'),
            '15': this.getVisualTimeSegmentParameters('Closed'),
            '30': this.getVisualTimeSegmentParameters('Closed'),
            '45': this.getVisualTimeSegmentParameters('Closed')
          })
        }
      }

      this.timeSegments = segments
    },

    buildSegment(start, end, timeslots) {
      return timeslots.some(timeslot =>
        this.isBetween(
          start,
          end,
          this.getTimeObjects(timeslot.startDateTime),
          this.getTimeObjects(timeslot.endDateTime)
        )
      )
    },

    getVisualTimeSegmentObject(reservations, start, end) {
      const res = reservations.find(reservation =>
        this.isBetween(
          start,
          end,
          this.getTimeObjects(reservation.start_datetime),
          this.getTimeObjects(reservation.end_datetime)
        )
      )
      return res
        ? this.getVisualTimeSegmentParameters(res)
        : this.getVisualTimeSegmentParameters('Free')
    },

    openingTime() {
      let time = moment(this.startTime)
        .tz(this.buildingTimezone)
        .endOf('day')

      this.buildingTimeslots.forEach(timeslot => {
        const startDateTime = moment(timeslot.startDateTime).tz(
          this.buildingTimezone
        )
        if (startDateTime.isBefore(time)) {
          time = startDateTime
        }
      })
      return time
    },

    closingTime() {
      let time = moment(this.endTime)
        .tz(this.buildingTimezone)
        .startOf('day')
      this.buildingTimeslots.forEach(timeslot => {
        const endDateTime = moment(timeslot.endDateTime).tz(
          this.buildingTimezone
        )
        if (endDateTime.isAfter(time)) {
          time = endDateTime
        }
      })
      return time
    },

    getTimeObjects(time) {
      return moment(
        moment(time)
          .tz(this.buildingTimezone)
          .format(this.timeFormat),
        this.timeFormat
      )
    },

    getVisualTimeSegmentParameters(type) {
      let title, vueColorSchemeParameter

      if (typeof type === 'object') {
        const start = this.getTimeObjects(type.start_datetime).format(
          this.timeFormat
        )
        const end = this.getTimeObjects(type.end_datetime).format(
          this.timeFormat
        )
        title = `Reserved from ${start} to ${end}`
        vueColorSchemeParameter = 'danger'
      } else {
        title = type
        vueColorSchemeParameter =
          type === 'Free' ? 'success' : 'light'
      }

      return { title, status: vueColorSchemeParameter }
    },

    isBetween(startTime, endTime, openingTime, closingTime) {
      const newClosing = closingTime.subtract(1, 'minute')
      return (
        startTime.isBetween(
          openingTime,
          newClosing,
          undefined,
          '[]'
        ) ||
        endTime.isBetween(openingTime, newClosing, undefined, '[]')
      )
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  display: flex;
  flex-direction: row;
}

.hr-wrapper {
  font-size: 12px;

  .hr-label {
    transform: translateX(-3.5px);
    display: block;
  }

  .hr-label-2 {
    transform: translateX(-7px);
    display: block;
  }
}

.flex-grow {
  flex-grow: 1;
}

.tile {
  flex-grow: 1;
  flex-shrink: 0;
  height: 35px;
}
</style>
