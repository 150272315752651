import { mapActions, mapGetters } from 'vuex'
import OkkuApi from '@/services/OkkuApi'

export default {
  data: () => ({
    selectedValue: {},
    localAttributes: [],
  }),
  watch: {
    allAttributes: {
      immediate: true,
      deep: true,
      handler(val) {
        if (val) {
          this.localAttributes = val
          if (this.selectedValue.id) {
            this.setSelectedAttributes()
          }
        }
      }
    }
  },
  computed: {
    // Maps getter from attributes store
    ...mapGetters('attributes',{allAttributes:'allAttributes'}),
  },
  methods: {
    // Maps all actions from attributes store
    ...mapActions('attributes', {setAttributes: 'setAttributes',setApiMessage:'setApiMessage',setApiResponseCode:'setApiResponseCode'}),
    
    // This will set all attributes inside the store and return true if succesful
    async getAllAttributes() {
      this.setAttributes(await OkkuApi.getAttributes())

      return true
    },
    // This will upsert an attribute using the API. Result will be stored inside the attributes store
    async upsertAttribute(payload) {
      this.setApiMessage(payload)
      // Reset response code for this request
      this.setApiResponseCode('')
      return OkkuApi.upsertAttribute(payload)
        .then(data => {
          this.setApiMessage(data.message)
          this.setApiResponseCode(200)
          return data
        })
        .catch(error => {
          console.log(`Failed to upsert attribute ${error.message}`)
          this.setApiMessage(error.message)
          this.setApiResponseCode(
            (error && error.response && error.response.status) || 400
          )
          return error
        })
    },
    // This will delete an attribute using the API. Result will be stored inside the attributes store
    async deleteAttribute(payload) {
      this.setApiMessage('')
      this.setApiResponseCode('')
      return OkkuApi.deleteAttribute(payload.id)
        .then(data => {
          this.setApiMessage(data.message)
          this.setApiResponseCode(200)
          return data
        })
        .catch(error => {
          this.setApiMessage(error.message)
          this.setApiResponseCode(400)
          return error
        })
    },
    // This will update an attribute using the API. Result will be stored inside the attributes store
    async updateAttributeAssociation(payload) {
      this.setApiMessage('')
      this.setApiResponseCode('')
      return OkkuApi.updateAttributeAssociation(payload)
        .then(data => {
          this.setApiMessage(data.message)
          this.setApiResponseCode(200)
          return data
        })
        .catch(error => {
          this.setApiMessage(error.message)
          this.setApiResponseCode(400)
          return error
        })
    },
    setSelectedAttributes() {
      this.localAttributes = this.localAttributes.map(attribute => {
        attribute.selected =
          this.selectedValue.attributes.findIndex(
            attr => attr.id === attribute.id
          ) !== -1
        return attribute
      })
    },
    refreshSelectedValue(values = []) {
      if (
        this.selectedValue &&
        this.selectedValue.id &&
        values.length
      ) {
        this.selectedValue =
          values.find(value => value.id === this.selectedValue.id) ||
          {}
        if (Object.keys(this.selectedValue).length) {
          this.setSelectedAttributes()
        }
      }
    },
    async showAttributesModal(desk) {
      this.selectedValue = desk
      this.setSelectedAttributes()
      this.$bvModal.show('modal-attributes')
    },
    updateSelectedValueAttributes(attribute) {
      this.selectedValue.attributes.push(attribute)
    }
  }
}
