<template>
  <b-modal id="modal-reserve" size="lg" scrollable :on-enter-key-press="reserveNow" hide-footer :title="selectedObject.roomName
    ? selectedObject.roomName + ', ' + selectedObject.name
    : selectedObject.name
    " @hidden="resetInvitedUserObject">
    <div class="d-block text-center">
      <p>
        {{ $t('reserveFor') }}
        <strong v-if="startTime && endTime">
          {{ startTime | moment('timezone', timezone, 'D MMMM') }},
          <br class="show-767" />
          {{ startTime | moment('timezone', timezone, 'H:mm') }}
          -
          {{ endTime | moment('timezone', timezone, 'H:mm') }}
        </strong>
      </p>
      <template v-if="canReserveForOtherUser && !isKioskPage">
        <div class="row">
          <div class="col-lg-6 col-12 text-left">
            <div class="form-group">
              <label size="sm">{{ $t('reserveForOtherUser') }}:
              </label>
              <UserSelector @updateUser="user => {
                reserveFor = user
              }
                " />
            </div>
          </div>
          <div class="col-lg-6 col-12 text-left">
            <div v-if="canReserveForRepeatedWeeks && !isKioskPage" class="form-group">
              <label size="sm">{{ $t('repeatWeekly') }}:</label>
              <input v-model="weeksNum" maxlength="2" class="form-control" />
            </div>
          </div>
        </div>
        <div class="row"></div>
      </template>
      <div class="row">
        <div class="col-12 text-left">
          <div v-if="showReservationSubject" class="form-group">
            <label>{{ $t('reservationSubject') }}:</label>
            <input v-model="reservationSubject" type="text" class="form-control" trim />
            <b-form-invalid-feedback v-if="reservationSubject.length > 255">
              {{ $t('reservationSubjectLengthWarning') }}
            </b-form-invalid-feedback>
          </div>
        </div>
      </div>
      <div v-if="isAllowedToInviteGuests && !isKioskPage" class="row">
        <p class="text-left ml-3">
          {{ $t('guestList') }}
          <b-button v-if="canInviteOneGuest" variant="secondary" size="sm" @click="addField">
            <font-awesome-icon icon="plus" /> {{ $t('add') }}
          </b-button>
        </p>
        <div class="col-12 text-left">
          <div v-if="invitedUsers.length > 0" class="row">
            <div class="col-5 text-left">
              <div class="form-group mb-0">
                <label>{{ $t('name') }}:</label>
              </div>
            </div>
            <div class="col-5 text-left">
              <div class="form-group mb-0">
                <label>{{ $t('email') }}:</label>
              </div>
            </div>
          </div>
          <div v-for="(user, index) in invitedUsers" :key="index" class="row align-items-center">
            <div class="col-5 text-left">
              <div class="form-group">
                <b-form-input v-model="user.name" type="text" class="form-control" @blur="validateForm"
                  @keyup="validateForm" />
              </div>
            </div>
            <div class="col-5 text-left">
              <div class="form-group">
                <input v-model="user.email" type="text" :class="['form-control', isEmailValid(user.email)]"
                  @blur="validateForm" @keyup="validateForm" />
              </div>
            </div>
            <div class="col-2 text-left">
              <div class="form-group">
                <span class="text-danger cursor-pointer" @click="removeField(index)">
                  <font-awesome-icon icon="times" />
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 text-left">
          <b-checkbox v-if="allowPrivateReservations && !isKioskPage" v-model="visibleToOthers" name="" :value="1"
            :unchecked-value="0">
            {{ $t('reservationVisibleToOthers') }}
          </b-checkbox>
        </div>
      </div>
      <hr />
      <div v-if="!isKioskPage">
        <label v-if="assetTypes.length" style="font-size:20px">
          {{ $t('reserveableAssets') }}
        </label>
        &nbsp;
        <span v-if="assetTypes.length" v-b-tooltip.hover class="tip-icon" :title="$t('reserveableAssetsHint')">
          <font-awesome-icon icon="question-circle" />
        </span>
      </div>

      <b-card v-for="(assetType, index) in assetTypes" :key="assetType.name" no-body class="mt-2">
        <b-card-header v-if="assetType.available" v-b-toggle="'accordion-' + index" class="p-1 pl-2"
          :class="['select-attribute']" style="text-align:left">
          {{ assetType.name }}
        </b-card-header>
        <b-collapse :id="'accordion-' + index">
          <b-card-body>
            <b-row>
              <div class="col-12 col-lg-2">
                <b-form-group id="fieldset-search" :label="$t('select')" label-for="input-search" label-align="left">
                  <b-checkbox v-model="assetType.checked" name="" style="text-align:left" :value="1" :unchecked-value="0"
                    :disabled="!assetType.available">
                    {{ assetType.name }}
                  </b-checkbox>
                </b-form-group>
              </div>
              <div class="col-12 col-lg-5">
                <b-form-group id="fieldset-qty" :label="$t('qty')" label-for="input-qty" label-align="left">
                  <b-form-input id="input-qty" v-model="assetType.qty" :placeholder="$t('qty')" type="number"
                    autocomplete="off" min="1" trim></b-form-input>
                </b-form-group>
              </div>
              <div class="col-12 col-lg-5">
                <b-form-group id="fieldset-comment" :label="$t('comment')" label-for="input-comment" label-align="left">
                  <b-form-input id="input-comment" v-model="assetType.comment" :placeholder="$t('comment')" trim
                    autocomplete="off"></b-form-input>
                </b-form-group>
              </div>
            </b-row>
          </b-card-body>
        </b-collapse>
      </b-card>
      <template v-if="errors.length">
        <label style="font-size:20px">
          {{ $t('changeAssetQty') }}
        </label>
        <div v-for="(error, index) in errors" :key="index" class="row">
          <div class="col-12 text-left">
            <label style="color: red;">{{ error }}</label>
          </div>
        </div>
      </template>
      <b-button class="mt-3" block :disabled="isDisabled" @click="reserveNow">
        <b-spinner v-if="isBusy" small></b-spinner>
        {{ $t('reserveNow') }}
      </b-button>
    </div>
  </b-modal>
</template>

<script>
import { get as _get } from 'lodash'
import moment from 'moment-timezone'
import { mapGetters, mapState } from 'vuex'

import OkkuApi from '@/services/OkkuApi'
import AttributesMixin from '@/mixins/AttributesMixin'
import BootstrapModalHOC from '@/components/hocs/BootstrapModal'
import UserSelector from '@/components/inputs/UserSelector'
import { emailRegex, ONLY_NUMBER } from '@/constants'

export default {
  components: {
    UserSelector,
    'b-modal': BootstrapModalHOC
  },
  mixins: [AttributesMixin],
  props: {
    isKiosk: {
      type: Boolean,
      default: false
    },
    selectedObject: {
      type: Object,
      required: true
    },
    startTime: {
      type: String,
      required: true
    },
    endTime: {
      type: String,
      required: true
    },
    isBusy: {
      type: Boolean,
      required: true
    },
    showReservationSubject: {
      type: Boolean,
      required: true
    }
  },
  data: () => ({
    errors: [],
    search: '',
    reserveFor: null,
    visibleToOthers: 1,
    users: [],
    weeksNum: 0,
    invitedUsers: [],
    isValidInfo: true,
    reservationSubject: '',
    assetTypes: []
  }),
  computed: {
    getAccordionName(v) {
      console.log('vvvvv', v)
      return `accordion-${v}`
    },
    ...mapState('common', {
      settings: 'organisationReservationSettings',
      isDashboardAdmin: '$isDashboardAdmin',
      isCustomerAdmin: '$isCustomerAdmin',
      isTeamLead: '$isTeamLead',
      isCustomerCoordinator: '$isCustomerCoordinator',
      userinfo: '$userInfo',
      organisation: '$organisation',
      keycloakUserProfile: 'keycloakUserProfile'
    }),
    ...mapGetters('common', {
      enableAllUsersToInviteGuests: 'enableAllUsersToInviteGuests',
      allowPrivateReservations: 'allowPrivateReservations',
      timezone: 'buildingTimezone'
    }),

    isKioskPage() {
      return this.$route.name === 'KioskReservationsPage'
    },

    isAllowedToInviteGuests() {
      return this.enableAllUsersToInviteGuests || this.canInviteGuests
    },
    canReserveForOtherUser() {
      return (
        this.isDashboardAdmin ||
        this.isCustomerAdmin ||
        this.isTeamLead ||
        this.isCustomerCoordinator
      )
    },

    canInviteGuests() {
      return (
        this.keycloakUserProfile &&
        this.keycloakUserProfile.attributes &&
        _get(
          this.keycloakUserProfile,
          'attributes.isAllowedToInviteGuests',
          false
        )
      )
    },
    canInviteOneGuest() {
      if (!this.selectedObject.type) {
        return false
      }
      if (
        this.selectedObject.type === 'DESK' &&
        this.invitedUsers.length === 1
      ) {
        return false
      }
      return true
    },
    canReserveForRepeatedWeeks() {
      return (
        this.isDashboardAdmin ||
        this.isCustomerAdmin ||
        this.isCustomerCoordinator
      )
    },

    isDisabled() {
      if (this.isBusy) return true
      if (this.reservationSubject.length > 255) return true

      return this.invitedUsers.length
        ? !this.isValidInfo ||
        this.invitedUsers.some(
          invitedUser =>
            invitedUser.email &&
            !emailRegex.test(invitedUser.email)
        )
        : false
    }
  },
  watch: {
    '$i18n.locale': function locale() {
      moment.locale(this.$i18n.locale)
    },
    userinfo() {
      this.reserveFor = { email: this.userinfo.user }
    },
    selectedObject(val) {
      if (Object.keys(val).length) {
        if (!this.isKiosk) {
          OkkuApi.getAssetTypes(this.startTime, this.endTime).then(
            assetTypes => {
              this.assetTypes = assetTypes
            }
          )
        }
      }
    },

    reservationSubject(val) {
      this.reservationSubject = val.replace(
        /[~`!@#$%*"|^&()_={}[\]:;,.<>+\\/?-]/g,
        ''
      )
    },
    weeksNum(value) {
      this.weeksNum = value?.replace(ONLY_NUMBER.number, '')
    }
  },
  mounted() {
    moment.locale(this.$i18n.locale)
    this.reserveFor = { email: this.userinfo.user }
  },
  methods: {
    addField() {
      this.invitedUsers.push({ name: '', email: '' })
    },
    removeField(index) {
      this.invitedUsers.splice(index, 1)
      this.validateForm()
    },
    isEmailValid(email) {
      if (email === '') return null

      return !emailRegex.test(email) ? 'is-invalid' : null
    },
    validateForm() {
      const validGuests = []
      this.invitedUsers.forEach(user => {
        if (user.name && user.email) {
          validGuests.push('guest')
        }
      })
      this.isValidInfo =
        validGuests.length === this.invitedUsers.length
    },
    reserveNow() {
      // Filter checked assets
      const assets = this.assetTypes.filter(asset => asset.checked)

      const errors = []
      assets.forEach(asset => {
        if (
          asset.reservationCount + parseInt(asset.qty, 10) >
          asset.limit
        ) {
          errors.push(
            `Only ${asset.limit - asset.reservationCount} ${asset.name
            } assets available but you asked for ${parseInt(
              asset.qty,
              10
            )}`
          )
        }
        if (!asset.qty || parseInt(asset.qty, 10) < 1) {
          errors.push(
            `Please enter valid quantity for ${asset.name} `
          )
        }
      })
      if (errors.length) {
        this.errors = errors
        return
      }
      this.errors = []

      if (
        this.invitedUsers.length === 1 &&
        this.invitedUsers[0].name === '' &&
        this.invitedUsers[0].email === ''
      ) {
        this.invitedUsers = []
      }
      this.$emit('reserve', {
        for: this.reserveFor,
        repeatWeeks: this.weeksNum,
        visibleToOthers: this.visibleToOthers,
        invitedUsers: this.invitedUsers,
        reserveForListUsers: this.users,
        reservationSubject: this.reservationSubject,
        reservationAssets: assets
      })
    },
    resetInvitedUserObject() {
      this.errors = []
      this.invitedUsers = []
      this.reservationSubject = ''
      this.reserveFor = { email: this.userinfo.user }
    }
  }
}
</script>
<style lang="scss" scoped>
@import '../../assets/scss/globals/mixins.scss';

.invalid-feedback {
  display: inline;
}

.form-group::v-deep {
  .inp-style {
    padding: 0;

    input {
      border: none;
      width: 100%;
      background-color: transparent;
      padding: 0.25rem 0.5rem;
    }
  }
}

.form-group-small::v-deep {
  width: 56px;
  display: inline-block;
  margin-bottom: 0;
  vertical-align: middle;

  input {
    text-align: center;
  }
}

.text-center {
  text-align: left !important;
}
</style>
