<template>
  <b-alert
    :dismissible="alert.dismissible"
    :fade="alert.fade"
    :variant="alert.variant"
    :show="alert.seconds"
    @dismiss-count-down="countDownChanged"
    @dismissed="alert.seconds = 0"
  >
    {{ alert.text }}
    <a
      v-show="alert.consentLink"
      target="_blank"
      :href="alert.consentLink"
      class="alert-link"
      >Link
    </a>
  </b-alert>
</template>

<script>
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState('common', {
      alert: 'alert'
    })
  },
  methods: {
    countDownChanged(seconds) {
      this.alert.seconds = seconds
    }
  }
}
</script>
